.table_text_left {
    text-align: center !important;
}

.timeModal .t-time {
    text-align: center;
    margin-top: 0px;
}

.reason_textarea {
    height: "100px";
    color: "var(--grey)";
    border-radius: "10px";
    box-shadow: "2px";
    resize: "none";
    width: "300px";
}

.blueText span {
    color: var(--blue);
}



.doc_action_modal {
    padding: 30px 40px;
}
