.multiple-option-check .check-field { width: 100%; float: left; margin: 18px 0 0 0;}
.white-btn:hover {
    color : "#fff" !important;
}

.vendorPhoneLabel {
    display: block;
    color: #c3c5c4;
    font-size: 13px;
    margin-bottom: 10px;
  }