.status-btn {
  align-items: center;
  display: flex;
  margin-left: 10px;
}

@media(max-width:768px)
{

  #manag-adm-stf-add-acticont{
    margin-top: -100px !important;
    margin-left: 170px !important;
  }
  
}
