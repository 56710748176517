.apl-btn_forg {
  background: #00a0df;
  color: #fff;
  border: 0px;
  text-transform: uppercase;
  padding: 6px 18px;
  font-weight: 600;
  border-radius: 30px;
  display: block;
  width: 200px;
  cursor: pointer;
  float: right;
  /* margin-top: -40px; */
}

.apl-btn_forg:hover {
  background: #0592c9;
}



.apl-btn_rst {
  background: #00a0df;
  color: #fff;
  border: 0px;
  text-transform: uppercase;
  padding: 6px 18px;
  font-weight: 600;
  border-radius: 30px;
  display: block;
  width: 100px;
  cursor: pointer;
  float: right;
  /* margin-top: -40px; */
}

#emlregist-send-btn {
  width: 100px !important;
  padding: 6px 18px !important;
  float: right;
}

.f-field-cont {
  width: 100px !important;
  padding: 0px;
  margin: 0px;
}

#cancel-btn {
  margin-left: 7px !important;
}

#btn-row-cont {
  padding-left: 24px !important;
  box-sizing: border-box;
}

@media (max-width:768px) {
  #btn-row-cont {
    padding-left: 0px !important;
    box-sizing: border-box;
  }
}