/* .new_chat_btn {
    padding: 10px 25px;
    border-radius: 10px;
    background-color: #fff;
}

.new_chat_btn img {
    width: 30px;
}

.new_chat_btn span {

    margin-left: 10px;
} */

.pt_50 {
    padding-top: 50px;
}

.g_chat {
    /* padding: 25px 0px; */
    border-right: 2px solid #d9d9d9;
}

.g_chat ul li {
    /* list-style: none;
    padding: 10px 10px;
    background-color: aliceblue;
    border-bottom: ridge; */
    /* width: 50%; */
    cursor: pointer;
}

.g_chat ul li:hover {
    background: #00a0df;
}

.g_chat .active {
    background: #00a0df;
}

.box_border {
    border: 1px solid #d9d9d9;
    border-radius: 20px;
}

.chat_header {
    height: 50px;
    font-size: 30px;
    color: #fff;
    background: #00a0df;
    padding-left: 20px;
}

.chat_circle {
    width: 30px;
    height: 30px;
    background-color: red;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 15px;
    margin-top: 10px;
    cursor: pointer;
}

.chat_list_delete_sec {
    cursor: pointer;
    width: 8%;
    display: inline-block;
}

.chat_list_sec {
    width: 80%;
    display: inline-block;
}

.ad_participant_row {
    padding-top: 20px !important;
}

.group_name_input {
    width: 100% !important
}