#react-btn-cont {
    display: flex;
    justify-content: center;
}

#rea-can-btn-div-cont {
    width: fit-content;
    margin-left: 28px;
}

#reast_can_btn {
    width: fit-content;
}

.apl-btn_forg {
    background: #00a0df;
    color: #fff;
    border: 0px;
    text-transform: uppercase;
    padding: 6px 18px;
    font-weight: 600;
    border-radius: 30px;
    display: block;
    width: 200px;
    cursor: pointer;
    float: right;
    /* margin-top: -40px; */
}

.apl-btn_forg:hover {
    background: #0592c9;
}

.apl-btn_rst {
    background: #00a0df;
    color: #fff;
    border: 0px;
    text-transform: uppercase;
    padding: 6px 18px;
    font-weight: 600;
    border-radius: 30px;
    display: block;
    width: 100px;
    cursor: pointer;
    float: right;
    /* margin-top: -40px; */
}