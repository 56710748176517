.headerTab ul li.active {
    background: #005d83 !important;
    color: #fff !important;
}

.headerTab ul {
    float: right;
    /* width: auto; */
    overflow: hidden;
    display: flex;
    padding: 0px;
    list-style: none;
    border: 1px solid #dcdbd8;
    border-radius: 5px;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 50%;

}

.headerTab ul li {

    float: left;
    height: 39px;
    font-size: 11px;
    text-transform: uppercase;
    padding: 10px 0px;
    border-left: 1px solid #dcdbd8;
    text-align: center;
    width: 33%;
    cursor: pointer;
    margin: 0;
}

.tab-app-information.activeLnkHead {
    display: block;
}

.edit_details {
    float: right;
    cursor: pointer;
    margin-bottom: 5px;
}

.edit_details_btn_sec {
    text-align: center;
}

.doc_action_modal .vn_frm {
    padding-top: 10px;
}



.doc_action_modal {
    padding: 30px 40px;
}