.c-logo-b {
  border: 4px solid #0091d9;
  text-align: center;
  border-radius: 100%;
  padding: 10px;
  position: relative;
  margin-top: 13px;
  height: 208;
  width: 208;
}

.ad input[type="file"] {
  display: none;
}
