.dec_res_btn {
    width: 10%;
    padding: 10px;
    border-radius: 10px;
    background: #00a0df;
    color: #fff;
}

.dataView_area {
    height: 150px;
    /* resize: none; */
    width: 90%;
    border-radius: 10px;
}

.enc_dec_div {
    padding: 10px 30px;
}