.Priority_high_btn{
    background: #993921;
    color: #fff;
    padding: 7px 20px;
    border-radius: 15px;
    text-transform: uppercase;
    font-size: 11px;
}
.Priority_medium_btn{
    background: #01A0DF;
    color: #fff;
    padding: 7px 9px;
    border-radius: 15px;
    text-transform: uppercase;
    font-size: 11px;
}
.Priority_low_btn{
    background: #D6AE77;
    color: #fff;
    padding: 7px 20px;
    border-radius: 15px;
    text-transform: uppercase;
    font-size: 11px;
}
.maintenance_active_btn{
    background: #01A0DF;
    color: #fff;
    padding: 7px 15px;
    border-radius: 15px;
    text-transform: uppercase;
    font-size: 11px;
}
.maintenance_input_box{
    width: 115px;
    height: 30px;
    border-radius: 10px;
    border: 1px solid grey;
    text-align: center;
    box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 28%);
}