.bell_img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: 30px;
    background-color:#015D83;
    padding: 8px;
}
.reminder_head{
    display: flex;
}
.toogle_switch{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
}
.new_button{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    padding-left: 25%;
}