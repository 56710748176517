/* body{
  padding: 0px;
  margin: 0px;
} */

.ck-editor__editable_inline {
  min-height: 200px;
}

.editor_pad-opi {
  padding-top: 15px;
}

.header_ch {
  background-color: #00a0df !important;
  color: #fff !important;
}

.app {
  width: 100%;
  box-sizing: border-box;
}

.room {
  background: #005f81;
  position: fixed;
  width: 100%;
  height: 100%;
}

#button-bar {
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10vh;
  text-align: center;
}

#button-bar ul {
  display: inline-block;
  margin: 0;
  padding: 0;
  /* For IE, the outcast */
  zoom: 1;
  display: inline;
  list-style-type: none;
}

#button-bar ul li {
  display: inline-block;
  background-color: #005f81;
  font-weight: bold;
  margin: 0 auto 0 10px;
  color: white;
  border: none;
  padding: 0;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  cursor: pointer;
  font-size: 24px;
  text-align: center;
}

#button-bar ul li svg {
  margin: 18px auto 0;
  display: block;
}

#button-bar ul li:hover {
  background-color: #00B6FF !important;
}

.video {
  -webkit-transform: scaleX(1, 1);
  transform: scaleX(1, 1);
}

#chat-bar {
  background-color: #fff;
  height: 60vh;
  min-height: 300px;
  width: 30%;
  overflow: auto !important;
  position: fixed;
  right: 10px;
  bottom: 0;
  z-index: 999;
  border-radius: 15px;
}

#participant-bar {
  width: 95vw;
  display: flex;
  overflow-x: auto;
  margin: 10px auto 0;
  justify-content: center;
}

.participant-each {
  width: 150px;
  height: 140px;
  flex: 0 0 auto;
  margin: 0 10px;
  background: #fff;
  border-radius: 3px;
  overflow: hidden;
  position: relative;
}

.identity-text {
  text-align: center;
  font-size: 14px;
  line-height: 16px;
  position: absolute;
  left: 0;
  margin: 0 auto;
  right: 0;
  z-index: 9;
}

.participant-each video {
  width: 100%;
  height: 100%;
}

#dominant-participant {
  height: 480px;
}

.participant-each .track.video {
  display: block;
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
}

.lobby {
  margin-top: 100px;
  text-align: center;
  font-size: 1.25em;
}

.participants {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-left: -.75vw;
}

.participant {
  box-sizing: border-box;
  position: relative;
  border-radius: 8px;
  margin-left: .75vw;
  margin-bottom: .75vw;
  width: 97%;
  overflow: hidden;
  height: auto;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  display: flex;
  justify-content: center;
}

.participant-each .identity {
  color: #000 !important;
  font-size: 14px;
  line-height: 14px;
  padding: 0 0 6px;
  text-align: center;
  text-transform: capitalize;
  position: inherit;
}

.track.video video {
  width: 95%;
  height: 100%;
  display: block;
}

.identity {
  position: relative;
  left: 0px;
  bottom: 15px;
  color: white;
  padding-left: 15px;
  box-sizing: border-box;
  z-index: 10;
}

/* // .participant-each .track.video {
//   width: 150px;
//   height: 128px;
// } */
.identity-text {
  text-align: center;
  font-size: 14px;
  line-height: 16px;
}


/* button {
  background-color: #fe019a;
  font-weight: bold;
  display: block;
  margin: 0 auto;
  color: white;
  border: none;
  padding: 15px;
  border-radius: 50%;
  width: 50px;
  height:50px;
  cursor: pointer;
  font-size: 8px;
} */

/* button:disabled {
  opacity: .4;
  cursor:initial;
} */

#leaveRoom {
  margin-top: 25px;
  margin-left: 0;
  background-color: #a13427 !important;
}

.bb {
  margin-top: 25px;
  margin-left: 5px;
}

/* input {
  padding-bottom: 5px;
  border: none;
  outline: none;
  border-bottom: 1px solid #555;
  margin-bottom: 25px;
  width: 200px;
  font-size: 1em;
  text-align: center;
  border-radius: 0;
} */
.lobby label {
  display: block;
  margin: 0 auto 12px;
}

.lobby label input[type="checkbox"] {
  width: auto;
  margin: 0 12px 0 0;
}

.lobby button {
  background-color: #fe019a;
  font-weight: bold;
  display: block;
  margin: 0 auto;
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  cursor: pointer;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  padding: 0;
}

.joiningFieldError {
  color: red;
  font-size: 12px;
}

/* /////////// For share modal................... */

.index-03 {
  position: absolute;
  border: .1px solid #900;
  border-bottom: none;
  left: 0px;
  top: 102px;
  width: 900px;
  height: 27px;
}

.share_card_body {
  padding: 5px;
  background-color: #cfcfcf;
}

/* Code add 11012024 */



.inviteActionMod_pt {
  cursor: pointer;
  padding-top: 15px;
  font-size: 18px;
}

.pt_15_iam {
  padding-top: 15px;
}

.audio-modal {
  max-width: 60% !important;
}

.audio-modal .head {
  margin: auto;
  font-size: 24px;
}

.audio-modal .multi-btn {
  padding: 10px 50px;
  border-radius: 10px;
  background: grey;
}

.audio-modal .mul-active {
  padding: 10px 50px;
  border-radius: 10px;
  background: skyblue;
}

.audio-modal .call_mul_btn {
  padding: 10px 30px;
  border-radius: 10px;
  background: green;
  color: #fff;
  margin-left: 15px;
}

.audio-modal .t-right {
  text-align: right;
}


/* Style the container for the text box */
.audio-modal .search-container {
  position: relative;
}

/* Style the text box */
.audio-modal .search-box {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  width: 90%;
  font-size: 18px;
}

.audio-modal .pt-15 {
  padding-top: 15px;
}

.contact-mod .pt-10 {
  padding-top: 10px;
  display: inline-flex;

}

.smsBody {
  height: 200px;
  width: 90%;
  border-radius: 10px;
  resize: none;
}

.sms_numberInput {
  width: 60%;
  height: 40px;
  border-radius: 5px;
  border: 2px solid darkgrey;
}

.contact_sms_icon {
  padding-left: 20px;
  cursor: pointer;
}

.contact-mod .name_con {
  margin: auto
}

.contact-mod .modal-body {
  height: 300px;
  overflow-y: auto;
}

.call_mod .modal-content {
  background: #00a0df !important;
}

.call_mod .join_btn {
  padding: 10px 60px;
  border-radius: 10px;
  background: lightgreen;
}

.call_mod .pt_15_iam {
  text-align: center;
}

.call_mod .hdng {
  color: #fff;
  font-size: 24px;
}


/* ...........code end for share.......... */

/* ...........code end for share.......... */





.sml_vdo_top_icn ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: end;
  gap: 10px;
}

.video_sec {
  background: #005e84;
  position: fixed;
  width: 100%;
  height: 100%;
}

.video_sec_head h4 {
  color: #fff;
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.video_sec_head p {
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  margin-top: 5px;
}

.main_video_non img {
  display: block;
  margin: 0 auto;
  width: 20% !important;
}

.video_id_blo strong {
  display: block;
  color: #fff;
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
  margin: 10px 0 3px;
}

.video_id_blo span {
  display: block;
  color: #fff;
  font-size: 10px;
  text-transform: uppercase;
}

.sml_vdo {
  /* background: transparent; */
  background: #005f81;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  padding: 10px;
  width: 200px;
  border-radius: 10px;
}

.sml_vdo>img {
  display: block;
  margin: 0 auto;
  width: 50px;
  padding: 45px 45px;
}

.sml_vdo .track .video video {
  height: 200px !important;
  width: 200px !important;
}

.video_button_sec img {
  display: block;
  margin: 0 auto;
}

.video_thumb {
  position: absolute;
  bottom: 70px;
  width: 90%;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: flex;
  gap: 30px;
  overflow: hidden;
  
}
.video_thumb:hover{overflow-x: scroll;}
.video_button_sec {
  position: absolute;
  bottom: 16px;
  width: 100%;
}

.video_sec_head {
  margin-left: 5%;
}

/* Chrome, Firefox, Opera, Safari 10.1+ */
/* Firefox */
/* input::placeholder { 
  color: red;
  opacity: 1; 
} */
/* Internet Explorer 10-11 */
/* input:-ms-input-placeholder { 
  color: red;
} */
/* Microsoft Edge */
/* input::-ms-input-placeholder { 
  color: red;
} */

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* semi-transparent background */
  z-index: 9999;
  /* Higher z-index to ensure it's above other content */
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1048px) {
  .app {
    padding: 0px;
  }

  #participant-bar {
    width: 500px;
    display: flex;
    overflow-x: auto;
  }

  @media (orientation:landscape) {
    .participant {
      width: 97%;
      margin-left: auto;
      margin-right: auto;
      height: 96vh;
    }

  }

}

@media (max-width: 767px) {
  .participant .video {
    width: 100% !important;
    height: auto !important;
  }

  #dominant-participant {
    height: 72vh;
  }

  .participant {
    width: 100%;
    padding: 5px !important;
    margin: 0 !important;
    height: 100%;
  }

  .video_thumb {
    bottom: 0;
    position: relative;
    width: 90%;
    overflow-x: scroll;
  }

  .res_load {
    width: 100% !important;
  }

  .body.modal-open {
    padding-right: 0px !important;
  }

  .video_thumb .identity-text {
    font-size: 11px;
    line-height: 8px;
  }

  .participant-each {
    width: 130px;
    height: 98px;
    position: relative;
    background: transparent;
    display: inline-block;
  }

  #chat-bar {
    height: 59vh;
    width: 78%;
  }

  .details_job_box h6 {
    font-size: 13px;
    margin-bottom: 0.3rem;
  }
}

@media (max-width: 560px) {
  .participant {
    height: 60%;
    position: absolute;
    top: 13%;
}
  #chat-bar {
    height: 59vh;
    width: 78%;
  }

  .details_job_box h6 {
    font-size: 13px;
    margin-bottom: 0.3rem;
  }
  .video_thumb {
    bottom: 98px;
    width: 90%;
    position: absolute;
}
.participant-each {
  width: 130px;
  height: 98px;
  position: relative;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
}
.track.video video{border-radius: 0 0;}
}