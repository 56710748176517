.status-btn {
  align-items: center;
  display: flex;
  margin-left: 15px;
  margin-bottom: 10px;
}

.tooltip-inner {
  background-color: #44037a;
  color: cyan;
}
.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
    border-top-color: #44037a;
}
