.mt_10 {
    margin-top: 10px
}

.head_note {
    text-align: center;
}

.head_note p {
    font-size: 20px;
}

.decline_mod_header {
    color: #fff;
    background: brown;
}

.imp{
    color: red;
}