.bg-form{ background:url(../images/bg1.jpg) repeat; width:100%; height:100%; position: fixed;}
.login-application { width: 410px;position: absolute; display: block; top: 50%; left: 50%; margin-right: -50%;transform: translate(-50%, -50%);background: #fff; border-radius: 15px;box-shadow: 0px 0px 8px rgb(0 0 0 / 46%);}
.in-field:focus{ outline:none}
.lg-logo{ margin-top:30px; margin-bottom:20px;}
.lg-logo img{    display: inline-block; max-width: 150px; margin: 30px 0 0 0;}
.login-account-wrap{ background:#005e84; padding:20px 65px 50px; border-radius:0 0 12px 12px}
.login-account-wrap h3{color: #fff; font-size: 15px; text-align: center;     font-weight: 500;margin: 0 0 21px 0;}
.form-row-a{ width:100%; float:left; margin:7px 0px;}
.in-field{width: 100%; border-radius: 9px;border: 0px; height: 38px; padding: 9px 11px; font-size: 15px;}
.forgot-pass-link{color: #e0f6ff; font-size: 14px; font-style: italic; text-decoration: underline; display: inline-block; float: right;}
.forgot-pass-link:hover{ color:#fff; text-decoration:none}
.login-sbt{     position: absolute; background: none; border: 0px; width: 125px; bottom: -40px; right: -60px; cursor:pointer}
