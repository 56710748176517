.f2f-b {
  background: #333;
  display: inline-block;
  width: 35px;
  height: 37px;
  color: #fff;
  text-align: center;
  border-radius: 100%;
  padding-top: 8px;
}
p.notes span {
  color: var(--blue);
}
.drpdownTd {
  padding-left: 7% !important;
}
.drpdown_clientRFQDetails {
  width: 70%;
}

.approve_client_box {
  width: 50%;
  height: 300px;
  background-color: lightgray;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  border-radius: 20px;
  flex-direction: column;
}

.centered-text {
  text-align: center;
  font-size: 24px;
  margin-bottom: 30px;
}

.centered-button {
  text-align: center;
  background: #00a0df;
  border: 0px;
  color: #fff;
  padding: 18px 25px;
  font-weight: 400;
  border-radius: 15px;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  margin: 0 5px;
  font-size: 11px;
  line-height: 11px;
  transition: transform 0.3s;
}

.centered-button:hover{
  background-color: #0178ff;
  transform: scale(1.2);
}