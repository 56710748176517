.Store_inactive_btn{
    background: #B0B3B2;
    color: #fff;
    padding: 7px 9px;
    border-radius: 15px;
    text-transform: uppercase;
    font-size: 11px;
}
.Store_active_btn{
    background: #01A0DF;
    color: #fff;
    padding: 7px 9px;
    border-radius: 15px;
    text-transform: uppercase;
    font-size: 11px;
}
.store_logo_field{
    display: flex;
    justify-content: center;
    align-items: center;
}
.store_logo{
    width: 42px;
    height: 42px;
    position: relative;
    background-size: 100% 100%;
    margin-right: 10px;
}