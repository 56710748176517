.invoc_pge .vn_frm > span {
    width: auto;
    margin-right: 5px;
  }
  .val_fig{font-weight: 400; display: inline-block; vertical-align: middle; margin-top: 9px; font-size: 13px; color: var(--grey);}
  .invoc_pge  .checkmark2 {
    position: absolute;
    height: 16px;
    width: 16px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #005d83;
  }
  .custom_check2 strong {
    font-size: 13px;
    color: gray;
    font-weight: 400;
    position: absolute;
    left: 20px;
    top: -12px;
  }
.ad_rem a{margin: 0 3px;}
.invoc_pge table td a{text-decoration: none;}
.invoc_pge .cus-filter-btn {
    float: left;
  }
  .invoc_pge .filter-pagination{margin: 0 10px;}
  .inv_src {
    margin-top: 20px;
  }
  .paid-btn{  background: #65A57C;
    color: #fff;
    padding: 7px 9px;
    border-radius: 15px;
    text-transform: uppercase;
    font-size: 11px;
    width: 94px;
    display: block;
    margin: 0 auto;  }
 .unpad-btn{  background: #CC9F53;
    color: #fff;
    padding: 7px 9px;
    border-radius: 15px;
    text-transform: uppercase;
    width: 94px;
    display: block;
    margin: 0 auto;    font-size: 11px;
  }
 .overd-btn{  background: #6F7271;
    color: #fff;
    padding: 7px 9px;
    border-radius: 15px;
    text-transform: uppercase;
    font-size: 11px;
    width: 94px;
display: block;
margin: 0 auto;
  }
  .apply-butn {
    display: inline-block;
    vertical-align: middle;
    margin: 4px 0 0;
  }
  .apply-butn a {
    background: #00a0df;
    color: #fff;
    padding: 7px 20px;
    border-radius: 15px;
    text-transform: uppercase;
    font-size: 11px;
 display: block;
margin: 0 auto;
text-decoration: none;  }
.vewaljobs .table-listing-app table td.pric_grn {
    color: #65A57C;
    font-weight: 600;
    font-size: 14px !important;
  }

  .invoiceLabel{
    color: #A39E9E;
    font-weight: 700;
  }
   .invoiceLogo{
    border: 2px solid #0091d9;
    text-align: center;
    border-radius: 100%;
    padding: 10px;
    position: relative;
    margin-top: 13px;
    width: 125px;
    height: 125px;
  }
  .invoiceLogo img {
    width: 120px;
    height: 120px;
    object-fit: cover;
  }
  .invoiceTemplateColumn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px;
  }
  .invoiceColumnList{
    border:1px solid rgb(223, 222, 222);
    border-radius: 10px;
    height: 380px;
    width: 300px;
    padding: 10px;
   
  }
  .availableInvoiceCheckBox{
    overflow-x: hidden;
    overflow-y: auto;
    height: 290px;
    width: 280px;
    float: left;
    margin: 10px 0 22px 0px;
  }

  .availableInvoiceCheckBox::-webkit-scrollbar {
    width: 12px;
}

.availableInvoiceCheckBox::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 10px;
   
}

.availableInvoiceCheckBox::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
    background-color: #01A0DF;
}
  .availableListData{
    padding: 10px;
  }
  .invoiceColumnBtn{
    display: flex;
    justify-content: end;
  }
  .invoiceSwitch{
    margin-top: 7px;
  }



.previewInvoiceBtn{
    text-align: center;
    text-decoration: none;
    font-size: 12px;
    border: 3px solid #01A0DF;
    color:#01A0DF
}
.addPayableItem{
    float: right;
    width: 20%;
    margin-top: 10px;
}
.in-field2-payable{
    width: 80%;
    border: 0px;
    border-bottom: 1px solid #dadada;
    padding: 4px;
    color: var(--grey);
}