.av_training_table {
    width: 100% !important;
    border: 0
}

.av_training_table .viewlink {
    text-decoration: none;
}

.tr_dt .bts-drop {
    width: 64%;
}

.tr_dt .mt-20{
    margin-top: 20px;
}

.tr_dt .blue-btn{
    text-decoration: none;
    color: #fff !important;
}

.av_training_table .inputfield{
    width: 90% !important;
}

.av_training_table .bts-drop {
    /* width: 90% !important; */
    text-align: center !important;
    padding: 0px 5px;
}
.bts-drop.rating_stats {
    width: 84px !important;
}