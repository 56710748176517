/* /////////// For share modal................... */

.index-03 {
  position: absolute;
  border: .1px solid #900;
  border-bottom: none;
  left: 0px;
  top: 102px;
  width: 900px;
  height: 27px;
}

.share_card_body {
  padding: 5px;
  background-color: #cfcfcf;
}




.vewaljobs .table-listing-app.jobdetails table td {
  display: inline-block;
  padding: 14px;
  text-align: left;
  border-bottom: 1px solid #ccc;
  font-size: 17px !important;
}

.table-listing-app.jobdetails .tble-row {
  padding: 22px;
}

.verificaiton-doc-tab.ven.jobdtls li {
  width: 20%;
  font-size: 14px;
  line-height: 22px;
}

.jobdtls.verificaiton-doc-tab.ven ul li {
  background: #005d83;
  color: #fff;
}

.jobdtls.verificaiton-doc-tab ul li.active {
  background: #fff;
  color: #005d83;
}

.job-details-tab.jobdltste .participants-chat-row.reply .chatinfo-bx,
.job-details-tab.jobdltste .participants-chat-row .chatinfo-bx {
  width: 55%;
}

.job-details-tab.jobdltste .participants-chat-row.reply .chatinfo-bx .cht-user {
  color: #b2eaff;
}

.job-details-tab.jobdltste .participants-chat-row .chatinfo-bx .cht-user,
.job-details-tab.jobdltste .participants-chat-row.reply .chatinfo-bx .cht-user p,
.job-details-tab.jobdltste .participants-chat-row .chatinfo-bx .cht-user p {
  color: #7d807f;
}

.jobdltste.job-details-tab .prticipants-area a.add-part-btn {
  text-decoration: none;
}

.jobdltste.job-details-tab .participants-chat-row figure {
  width: 60px;
  height: 60px;
}

.job-details-tab.jobdltste>ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.job-details-tab.jobdltste ul.nav-tabs li {
  width: 20%;
  text-align: center;
}

.job-section-tab .add-more-field .table-listing-app table,
#jobdetails.tab-pane .job-section-tab table {
  background: url(../../../../images/job-detail-watermark.png) no-repeat center center;
}

.addmore_service.text-right a {
  text-decoration: none;
  background-position: 0 center;
}

.job-section-tab .add-more-field .table-listing-app table th {
  border-top: 1px solid #e3e3e3;
  text-align: left;
  font-size: 15px !important;
}

.job-section-tab .add-more-field .table-listing-app table td {
  text-align: left;
  font-size: 15px !important;
}

.job-section-tab .add-more-field .table-listing-app table th:nth-child(2),
.job-section-tab .add-more-field .table-listing-app table td:nth-child(2) {
  border-left: 1px solid #e3e3e3;
  text-align: right;
}

.job-section-tab .add-more-field .table-listing-app table td .inputfield.flr {
  width: 40%;
  text-align: center;
  color: var(--grey);
}















#vendoroff .job-section-tab table th {
  text-align: center;
  text-transform: capitalize;
  border-left: 0;
  font-weight: 500;
}

.rat_txt {
  display: inline-block;
  vertical-align: middle;
  line-height: 14px;
  padding: 2px 0 0 2px;
}

.job-details-tab._fl.sdw.vend_assign_pge ul.nav-tabs.stb .nav-link {
  padding: 16px 9px 16px 6px;
  line-height: 28px;
}

.job-details-tab._fl.sdw.vend_assign_pge ul.nav-tabs.stb li {
  width: auto;
}

.job-details-tab._fl.sdw.vend_assign_pge ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}



#vendoroff a.grey-btn,
a.blue-btn {
  text-transform: uppercase;
  text-decoration: none;
}

#jobdetails.tab-pane .job-section-tab table {
  background: url(../../../../images/job-detail-watermark.png) no-repeat center center;
}


/* css for bid modaal btn*/
.bidAssignBtn {
  border: 0px;
  text-transform: uppercase;
  padding: 10px 12px;
  font-weight: 400;
  border-radius: 15px;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  margin: 11px 4px 0;
  font-size: 11px;
  line-height: 11px;
  background: #00a0df;
  /* color: rgb(255 255 255 / 78%); */
  color: white !important;
  text-decoration: none;
}

.bidAssignBtn.a :hover {
  background: #005d83;
  text-decoration: none;
  color: white;
}






#Document .tblt td a.btns {
  color: #fff;
  padding: 5px 6px;
  border-radius: 6px;
  display: block;
  width: 80%;
  margin: 0 auto;
  text-decoration: none;
}

#Document .tblt td a {
  text-decoration: none;
}

#Document .tblt td a.btns.btn-green {
  background: #65A57C;
}

#Document .tblt td a.btns.btn-blue {
  background: #00A0DF;
}

#Document .tblt td a.btns.btn-drk-blue {
  background: #005D83;
}

.payblercble .job-details-tab ul.nav-tabs.stb li {
  width: 16.66%;
}

.actn-pr {
  display: flex;
}

.actn-pr a {
  margin: 0 2px;
}

.acc_payable h3 {
  font-size: 16px;
  padding-bottom: 0px;
}

.acc_payable {
  padding-bottom: 50px;
  overflow: hidden;
}

.acc_payable:nth-child(2) {
  padding-bottom: 0;
}

.mr_0 {
  margin: 0px 0px !important;
}

.remarks_textarea {
  width: 95%;
  height: 100px;
  resize: none;
  color: var(--grey);
  border: 1px solid #d8d7d4;
  padding: 0 10px;
  border-radius: 10px;
}

.total_quote_payable {
  font-size: 30px !important;
  padding-top: 15px;
}

.available_vendor_filter_btn_div{
  display: flex;
  gap: 20px;
  justify-content: end;
}


/* Common styling for the buttons */
.vendoroff_filter_submit {
  padding: 10px 40px;
  font-size: 14px;
  border: none;
  background-color: #3498db;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 15px;
}

/* Styling when the button is hovered */
.vendoroff_filter_submit:hover {
  background-color: #2980b9;
  color: white;
}
/* Common styling for the buttons */
.vendoroff_filter_cancel {
  padding: 10px 40px;
  font-size: 14px;
  border: none;
  background-color: #fff;
  color: #3498db;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 15px;
  border: 1px solid #b6b7b7;
}

/* Styling when the button is hovered */
.vendoroff_filter_cancel:hover {
  background-color: #2980b9;
  color: white;
}