.book_se button {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: white;
    border-color: hsl(0, 0%, 80%);
    border-radius: 10px;
    border-style: solid;
    border-width: 1px;
    box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 28%);
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    min-height: 60px;
    outline: 0 !important;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    box-sizing: border-box;
    height: 60px;
    width: 100%;
    padding: 20px;
    font-size: 16px;
    color: gray;
    font-weight: 500;
}

.book_se button:hover {
    background-color: #005d83;
    color: #fff;
}

.book_se p {
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0px 15px;
    margin: 10px 0 0;
    color: #646464;
    text-align: center;
}

.book_se {
    margin: 30px 0;
}

.pop_hd {
    padding-bottom: 20px;
}

.pop_hd img {
    display: block;
    margin: 0px auto 20px;
}

.pop_hd p {
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: var(--grey);
    text-align: center;
}

.pop_hd p span {
    color: #00a0df;
}

.modal-dialog.modal-lg.mod-ppop .modal-content {
    border-radius: 14px;
}

.modal-dialog.modal-lg.mod-ppop .modal-content .close {
    margin: 10px 10px 0 0;
}

.pops_undr_pop.mod-ppop-inr {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 14px;
    outline: 0;
}

.mod-ppop-inr .blue {
    background: #035d83;
    border: 0;
    display: block;
    height: auto;
    width: 250px;
    padding: 20px 0px;
    border-radius: 20px;
    color: #fff;
    font-size: 20px;
    line-height: 22px;
    margin: 0 auto 14px;
    text-transform: uppercase;
}

.mod-ppop-inr p strong {
    font-size: 24px;
    line-height: 24px;
    display: block;
    text-align: center;
    margin: 20px 0 40px;
}

.mod-ppop-inr p {
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    color: grey;
}

.mod-ppop-inr .web-form-bx {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.mod-ppop-inr .web-form-bx .check-field {
    width: auto;
    float: left;
    margin: 0 30px 30px;
}

.pops_undr_pop.mod-ppop-inr {
    position: absolute;
    width: 96%;
    bottom: -64px;
    z-index: 9;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.mod-ppop-inr-und,
.web-form-bx,
.book_se {
    position: relative;
}

/* .my-modal_out {
    width: 35vw !important;
    max-width: 50vw !important;
} */

.border-radius-2 div {
    border-radius: 30px !important;
  }