.notification-btn {
  text-decoration: none !important;
  padding: 25px 40px !important;
  font-size: 14px !important;
  border-radius: 30px/50px !important;
}
:root {
  --color: red;
  --ck-border-radius: 50px;
}

.ck-editor {
  /* color: var(--color); */
  /* border-radius: var( --ck-border-radius); */
  --ck-border-radius: 10px;

  --ck-color-base-foreground: var(--ck-custom-background);
 
}